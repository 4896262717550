export const DEPARTMENTS = [
    'Logistics',
    'Trade',
    'Customer Service',
    'Export',
    'Wholesale',
    'Finance',
    'Human Resource',
    'Wholesale Manager',
    'Super Admin',
];

export const REQUEST_TYPES = [
    'Logistics',
    'Work Tools',
    'Packaging',
    'Trade Funding',
    'Salaries',
    'Airtime / Data',
    'Security',
    'Utilities',
    'Internet',
    'Condolence',
    'Bonus',
    'Salary Advance',
    'Misc',
];

export const ITEMS_PER_PAGE = 20;

export const NIGERIAN_STATES = [
    'Abia',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Edo',
    'Ekiti',
    'Enugu',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
    'Abuja',
];
